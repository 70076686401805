import React, { useContext, useState } from 'react'
import { IPContext } from '../App'
import copy from 'copy-to-clipboard'

interface Props {
  version: 'v4' | 'v6'
}

export const IpAddressField = ({ version }: Props): React.ReactElement => {
  const ipContext = useContext(IPContext)
  const ipAddress = version === 'v4' ? ipContext.ipv4 : ipContext.ipv6
  const [wasCopied, setWasCopied] = useState<boolean>(false)

  const ipExists = typeof ipAddress === 'string' && ipAddress !== 'loading'

  const onCopy = () => {
    if (!ipExists) {
      return
    }
    if (copy(ipAddress as string)) {
      setWasCopied(true)
    }
  }

  return (
    <div
      className={`rounded p-2 mb-3 d-flex justify-content-between align-items-center ${ipExists ? 'cursor-pointer' : ''} copy-container`}
      id="v4"
      onClick={onCopy}
      onTransitionEnd={() => setWasCopied(false)}
    >
      <div className="col p-0"/>
      <div id="v4-address" className="col p-0 text-center font-weight-bold lead">{ ipAddress ?? 'Not Available'}</div>
      <div className="col p-0 text-right">
        {
          ipExists && (
            <button
              className={`btn btn-sm btn-copy ${wasCopied ? 'btn-ripple animated' : 'btn-gray-400'} d-none d-sm-inline `}
              onAnimationEnd={() => { setWasCopied(false) }}
            >
              {wasCopied ? 'Copied' : 'Copy'}
            </button>
          )
        }
        <div
          className={`d-block d-sm-none ${wasCopied ? 'btn-ripple' : ''}`}
          onAnimationEnd={() => setWasCopied(false)}
        >
          {ipExists && <i className={`fas fa-copy`} />}
        </div>
      </div>
    </div>  )
}
