import React, { useEffect, useState } from 'react'
import { fetchIpAddress } from './utils'
import { Page } from './Components/Page'
import './styles/styles.scss'

// string is when IP exists. undefined is when it's not prefilled. null is when ip cannot be fetched
type IpContextValue = {
  ipv4: 'loading' | string | undefined | null
  ipv6: 'loading' | string | undefined | null
}

export const IPContext = React.createContext<IpContextValue>({
  ipv4: (window as any).ipv4,
  ipv6: (window as any).ipv6
})

type Language = 'de' | 'en'
const defaultLanguage: Language = 'en'

const LanguageContext = React.createContext<Language>(defaultLanguage)

function App() {
  const [ipv4, setIpv4] = useState<IpContextValue['ipv4']>((window as any).ipv4)
  const [ipv6, setIpv6] = useState<IpContextValue['ipv6']>((window as any).ipv6)
  const [language, setLanguage] = useState<Language>(defaultLanguage)

  const fetchUndefinedIpAddresses = async () => {
    if (ipv4 === undefined) {
      setIpv4('loading')
      const newIpv4 = await fetchIpAddress('v4')
      setIpv4(newIpv4)
    }
    if (ipv6 === undefined) {
      setIpv6('loading')
      const newIpv6 = await fetchIpAddress('v6')
      setIpv6(newIpv6)
    }
  }


  useEffect(() => {
    fetchUndefinedIpAddresses().catch(e => console.error(e))
  }, [])


  return (
    <IPContext.Provider value={{ ipv4, ipv6 }}>
      <LanguageContext.Provider value={language}>
        <Page />
      </LanguageContext.Provider>
    </IPContext.Provider>
  )
}

export default App
