export const fetchIpAddress = async (version: 'v4' | 'v6'): Promise<string | null> => {
  try {
    let response: Response
    if (version ==='v4') {
      response = await fetch(process.env.REACT_APP_ENDPOINT_V4 as string, { headers: { 'Accept': 'text/json' }})
    } else if (version === 'v6') {
      response = await fetch(process.env.REACT_APP_ENDPOINT_V6 as string, { headers: { 'Accept': 'text/json' }})
    } else {
      return null
    }

    if (!response.ok) {
      return null
    }
    const json = await response.json()
    if (typeof json.ip !== 'string') {
      return null
    }
    return json.ip
  } catch (e) {
    return null
  }
}