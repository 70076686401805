import React, { useState } from 'react'
import copy from 'copy-to-clipboard'

export const ConsoleSection = (): React.ReactElement => {
  const [wasV4Copied, setWasV4Copied] = useState(false)
  const [wasV6Copied, setWasV6Copied] = useState(false)
  const commandV4 = `curl -4 https://${window.location.host}`
  const commandV6 = `curl -6 https://${window.location.host}`

  const onClickCopyV4 = () => {
    if (copy(commandV4)) {
      setWasV4Copied(true)
    }
  }

  const onClickCopyV6 = () => {
    if (copy(commandV6)) {
      setWasV6Copied(true)
    }
  }

  return (
    <section className="py-5">
      <div className="inner-content-top-in-bottom-out">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 offset-sm-0 col-md-10 offset-md-1">
              <h3 className="mb-3 text-transform-none">Get IP address via console</h3>
              <p className="mb-3">Enter the command below in your command line interface to get your IPv4 address.</p>
              <div className="bg-black rounded p-3 mb-3 text-monospace text-gray-300 d-flex justify-content-between align-items-center">
                <span><span className="text-code-orange">curl</span> -4 https://{window.location.host}</span>
                <button
                  onClick={onClickCopyV4}
                  className={`btn btn-sm btn-copy ${wasV4Copied ? 'btn-ripple animated' : 'btn-gray-400'} d-none d-sm-inline `}
                  onAnimationEnd={() => { setWasV4Copied(false) }}
                >
                  {wasV4Copied ? 'Copied' : 'Copy'}
                </button>
              </div>
              <p className="mb-3">Enter the command below in your command line interface to get your IPv6 address.</p>
              <div className="bg-black rounded p-3 text-monospace text-gray-300 d-flex justify-content-between align-items-center">
                <span><span className="text-code-orange">curl</span> -6 https://{window.location.host}</span>
                <button
                  onClick={onClickCopyV6}
                  className={`btn btn-sm btn-copy ${wasV6Copied ? 'btn-ripple animated' : 'btn-primary-400'} d-none d-sm-inline `}
                  onAnimationEnd={() => { setWasV6Copied(false) }}
                >
                  {wasV6Copied ? 'Copied' : 'Copy'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
