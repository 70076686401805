import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { IpAddressField } from './IPAddressField'
import { ConsoleSection } from './ConsoleSection'
import background from '../images/background.jpg'

export const Page = (): React.ReactElement => {
  return (
    <div className="d-flex vh-100 flex-column">
      <Header />
      <main className="bg-gray-200">
        <section className="background-header bg-black" style={{ backgroundImage: `url(${background})` }}>
          <img className="header-icon d-none d-xl-block" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-12 text-white mb-2">
                <h1 className="text-transform-none text-center">Your IP addresses at a glance</h1>
              </div>
            </div>
            <div className="row">
              <div className="offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 text-white">
                <h6 className="text-transform-none text-center">Your IPv4 address</h6>
                <IpAddressField version={'v4'} />
                <h6 className="text-transform-none text-center">Your IPv6 address</h6>
                <IpAddressField version={'v6'} />
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: 0 }} className="container">
          <div className="supplement-section color-gray-200 bg-gray-200"></div>
        </div>
        <ConsoleSection />
      </main>
      <Footer />
    </div>
  )
}
